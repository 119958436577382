<template>
    <div>
        <nav class="float-left mg-r-20" v-if="data.lastPage > 1">
            <ul class="pagination">
                <li class="page-item" v-if="data.currPage > 1">
                    <a class="page-link" href="javascript:void(0);" @click="search(data.currPage - 1)" aria-label="Previous">
                        <span aria-hidden="true">«</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>
                <li class="page-item" v-if="(data.currPage - 4) >= 1"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage - 4)">{{data.currPage - 4}}</a></li>
                <li class="page-item" v-if="(data.currPage - 3) >= 1"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage - 3)">{{data.currPage - 3}}</a></li>
                <li class="page-item" v-if="(data.currPage - 2) >= 1"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage - 2)">{{data.currPage - 2}}</a></li>
                <li class="page-item" v-if="(data.currPage - 1) >= 1"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage - 1)">{{data.currPage - 1}}</a></li>
                <li class="page-item active"><a class="page-link" href="javascript:void(0);">{{data.currPage}}</a></li>
                <li class="page-item" v-if="(data.currPage + 1) <= data.lastPage"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage + 1)">{{data.currPage + 1}}</a></li>
                <li class="page-item" v-if="(data.currPage + 2) <= data.lastPage"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage + 2)">{{data.currPage + 2}}</a></li>
                <li class="page-item" v-if="(data.currPage + 3) <= data.lastPage"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage + 3)">{{data.currPage + 3}}</a></li>
                <li class="page-item" v-if="(data.currPage + 4) <= data.lastPage"><a class="page-link" href="javascript:void(0);" @click="search(data.currPage + 4)">{{data.currPage + 4}}</a></li>
                <li class="page-item" v-if="data.currPage < data.lastPage">
                    <a class="page-link" href="javascript:void(0);" @click="search(data.currPage + 1)" aria-label="Next">
                        <span aria-hidden="true">»</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
        <button class="btn btn-primary pd-x-25 pd-y-11 tx-uppercase tx-bold tx-spacing-6 tx-12" style="margin-bottom: 1rem;" @click="search(data.currPage)">Refresh</button>
        <p v-show="showCount" class="float-right text-muted" v-if="data.totalResults > 0 || data.total > 0">Showing {{data.from.toLocaleString()}} - {{to}} of {{data.totalResults.toLocaleString()}}</p>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
          data: Object,
          showCount: {
            type: Boolean,
            default: false
          }
        },
        data(){
            return {

            }
        },
        methods: {
            search(page){
                this.$emit('search', page);
            }
        },
        computed: {
            to: function(){
                if(this.data.to > this.data.totalResults){
                    return this.data.totalResults.toLocaleString();
                }else{
                    return this.data.to.toLocaleString();
                }
            }
        }
    }
</script>

<style scoped>

</style>
