<template>
    <div class="card-body" v-if="show">
        <div class="d-flex ht-300 pos-relative align-items-center">
            <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
            </div>
        </div>
        <p v-if="message.length > 0" class="tx-white tx-center">{{message}}</p>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: {
            show: Boolean,
            message: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style scoped>

</style>
