<template>
    <div>
        <div class="row">
            <div class="col-2">
                <div class="card shadow-base widget-11" style="cursor: pointer;" @click="toggleFilters">
                    <div class="card-header" style="border: none;">
                        <div class="card-title">Filters <span v-if="selectedFilters > 0">({{selectedFilters}} selected)</span></div>
                        <a href="javascript:void(0);"><i v-if="!filters.open" class="fa fa-angle-down"></i><i v-else class="fa fa-angle-up"></i></a>
                    </div><!-- card-header -->
                </div>
            </div>
            <div class="col-10">
                <pagination :data="pagination" @search="pagiSearch"></pagination>
            </div>
        </div>
        <div class="row">
            <div v-show="filters.open" class="col-2">
                <!-- SEARCH BUTTON -->
                <button v-show="!optionsMatch" @click="applyFilters" class="btn btn-primary mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p">APPLY FILTERS</button>

                <!-- CLEAR FILTERS BUTTON -->
                <button v-show="selectedFilters > 0" @click="clearFilters" class="btn btn-danger mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p">CLEAR FILTERS</button>

                <!-- TEXT FILTERS -->
                <div class="card shadow-base mg-t-20 widget-11">
                    <div class="card-header">
                        <div class="card-title">Search</div>
                    </div><!-- card-header -->
                    <div class="card-body">
                        <p class="tx-white">When searching for a plate, use ? in place of characters you don't know. For example, YB?F?</p>
                        <div class="form-group">
                            <input title="Use ? if you do not know a character!" type="text" v-model="filters.options.registration" class="form-control form-control-dark tx-14" placeholder="Vehicle Registration">
                        </div><!-- form-group -->
                        <div class="form-group">
                            <select v-model="filters.options.site" class="form-control select2" @change="searchCameras" v-select2>
                                <option :value="''" :selected="filters.options.site === ''">Select a Site</option>
                                <option v-for="s in sites" v-bind:value="s.id">{{$store.state.auth.demo ? 'Site' : s.name}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <select v-model="filters.options.camera" class="form-control select2" v-select2>
                                <option :value="''" :selected="filters.options.camera === ''">Select a Camera</option>
                                <option v-for="c in cameras" v-bind:value="c.id">{{$store.state.auth.demo ? 'Camera' : c.name}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <select v-model="filters.options.direction" class="form-control select2" v-select2>
                                <option :value="''" :selected="filters.options.direction === ''">Select a Direction</option>
                                <option v-for="d in directions" v-bind:value="d">{{d}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <select v-model="filters.options.status" class="form-control select2" v-select2>
                                <option :value="''" :selected="filters.options.status === ''">Select a Status</option>
                                <option :value="'blacklisted'">Blacklisted</option>
                                <option :value="'active'">Active</option>
                            </select>
                        </div>
                    </div><!-- card-block -->
                </div>

                <!-- TYPE FILTERS -->
                <div class="card shadow-base mg-t-20 widget-11">
                    <div class="card-header">
                        <div class="card-title">Time</div>
                    </div><!-- card-header -->
                    <div class="card-body">
                        <div class="form-group mg-b-0">
                            <label>Start Date</label>
                            <input type="date" v-model="filters.options.start_date" class="form-control form-control-dark tx-14" placeholder="yyyy-MM-dd">
                        </div><!-- form-group -->
                        <div class="form-group mg-b-0">
                            <label>End Date</label>
                            <input type="date" v-model="filters.options.end_date" class="form-control form-control-dark tx-14" placeholder="yyyy-MM-dd">
                        </div><!-- form-group -->
                        <div class="form-group mg-b-0">
                            <label>Start Time</label>
                            <input type="text" name="start_time" v-mask="{regex: '^(?:[01]\\d|2[0123]):(?:[012345]\\d):(?:[012345]\\d)$'}" v-model="filters.options.start_time" class="form-control form-control-dark tx-14" placeholder="hh:mm:ss">
                        </div><!-- form-group -->
                        <div class="form-group mg-b-0">
                            <label>End Time</label>
                            <input type="text" name="end_time" v-mask="{regex: '^(?:[01]\\d|2[0123]):(?:[012345]\\d):(?:[012345]\\d)$'}" v-model="filters.options.end_time" class="form-control form-control-dark tx-14" placeholder="hh:mm:ss">
                        </div><!-- form-group -->
                    </div><!-- card-block -->
                </div>

            </div>
            <div :class="{'col-10': filters.open, 'col-12': !filters.open}" class="mg-t-20">
                <loader :show="loading"></loader>
                <table v-show="!loading" width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>PHOTO</th>
                        <th>STATUS</th>
                        <th>PLATE</th>
                        <th>I/O</th>
                        <th>DATETIME</th>
                        <th>SITE</th>
                        <th>CAMERA</th>
                        <th v-if="$can('events-blacklist') || $can('events-unblacklist')">ACTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="e in events" :key="e.id + '-event'" v-if="!loading" style="cursor: pointer">
                        <td>{{e.id}}</td>
                        <td>
                            <secure-image v-if="e.image !== null" @click="showImage(e)" style="width: 75px; height: auto;" :filename="e.image" :site_id="e.site.id" :storage_account="e.storage_account"></secure-image>
<!--                            <img v-if="e.image !== null" :src="'https://api.varsanpr.com/test/admin/events/' + e.id + '/thumbnail?token='+user.socket_token+'&site_id=' + e.site.id" @click="showImage(e)" style="width: 75px; height: auto;">-->
                            <span v-else>Image Deleted</span>
                        </td>
                        <td @click="loadEvent(e.id)" v-if="e.status === 'blacklisted'">
                            Blacklisted <span v-if="e.national_blacklist">(On National Blacklist)</span>
                        </td>
                        <td @click="loadEvent(e.id)" v-if="e.status !== 'blacklisted'">
                            Active <span v-if="e.national_blacklist">(On National Blacklist)</span>
                        </td>
                        <td @click="loadEvent(e.id)">{{e.vehicle.plate}}</td>
                        <td @click="loadEvent(e.id)">{{e.io}}</td>
                        <td @click="loadEvent(e.id)">{{e.created_at}}</td>
                        <td @click="loadEvent(e.id)">{{$store.state.auth.demo ? 'Site' : e.site.name}}</td>
                        <td @click="loadEvent(e.id)">{{$store.state.auth.demo ? 'Camera' : e.camera.name}}</td>
                        <td v-if="e.blacklisted && $can('events-unblacklist')">
                            <button v-on:click.stop="unwatchlist(e)" class="btn btn-sm btn-success">Remove from Blacklist</button>
                        </td>
                        <td  v-if="!e.blacklisted && $can('events-blacklist')">
                            <button v-on:click.stop="watchlist(e)" class="btn btn-sm btn-danger">Add to Blacklist</button>
                        </td>
                    </tr>
                    <tr v-if="events.length < 1 && !loading">
                        <td colspan="9" class="text-center">No Events</td>
                    </tr>
                    </tbody>
                </table>
                <pagination :data="pagination" @search="pagiSearch"></pagination>

            </div>
        </div>

        <div id="eventImageModal" v-if="popups.event.showing" class="modal fade effect-just-me show" style="display: block; padding-right: 17px;">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 80% !important;" role="document">
                <div class="modal-content bd-0 tx-14">
                    <div class="modal-body pd-25">
                        <div class="row">
                            <div class="col-12">
                                <div class="img-magnifier-container">
                                    <secure-image v-show="!popups.event.imageLoading" :id="'event_image_popup'" :endpoint="'/api/event/image/'" :filename="popups.event.event.image" :storage_account="popups.event.event.storage_account" :site_id="popups.event.event.site.id" @load="imageLoaded" style="width: 100%; height: auto;"></secure-image>
                                </div>
                                <div v-show="popups.event.imageLoading">
                                    <div class="d-flex ht-300 pos-relative align-items-center">
                                        <div class="sk-folding-cube">
                                            <div class="sk-cube1 sk-cube"></div>
                                            <div class="sk-cube2 sk-cube"></div>
                                            <div class="sk-cube4 sk-cube"></div>
                                            <div class="sk-cube3 sk-cube"></div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <button class="btn btn-danger tx-11 tx-uppercase" @click="closeImage" style="position: absolute; top: 12px; right: 25px; z-index: 10000; font-size: 15px; font-weight: bold;" ><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>
    </div>
</template>

<script>
    import Loader from "@/components/Loader";
    import Pagination from "@/components/Pagination";
    import Swal from 'sweetalert2';
    import {DateTime} from "luxon";
    import SecureImage from "@/components/SecureImage";
    import axios from 'axios';
    import authHeader from "@/services/auth-header";
    export default {
        name: "Events",
        props: {
            showToday: Boolean,
            showBlacklistToday: Boolean,
            showWeek: Boolean,
            showBlacklistWeek: Boolean,
            showMonth: Boolean,
            showBlacklistMonth: Boolean,
            showBlacklist: Boolean
        },
        components: {SecureImage, Pagination, Loader},
        data(){
            return {
                events: [],
                pagination: {
                    currPage: 1,
                    totalResults: 0,
                    from: 0,
                    to: 0,
                    perPage: 0,
                    lastPage: 1,
                },
                filters: {
                    open: true,
                    options: {
                        registration: "",
                        site: "",
                        camera: "",
                        direction: "",
                        status: "",
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: ""
                    },
                    selectedOptions: {
                        registration: "",
                        site: "",
                        camera: "",
                        direction: "",
                        status: "",
                        start_date: "",
                        end_date: "",
                        start_time: "",
                        end_time: ""
                    },
                },
                loading: true,
                socket_token: "",
                sites: [],
                cameras: [],
                directions: [
                    "In",
                    "Out"
                ],
                popups: {
                    event: {
                        showing: false,
                        imageUrl: "",
                        imageLoading: false,
                    },
                }
            }
        },
        mounted() {
            this.socket_token = this.$store.state.auth.user.socket_token;
            if(this.showToday === true){
                this.filters.options.start_date = DateTime.local().format("yyyy-MM-dd");
            }else if(this.showBlacklistToday === true){
                this.filters.options.start_date = DateTime.local().format("yyyy-MM-dd");
                this.filters.options.status = 'blacklisted';
            }else if(this.showWeek === true){
                this.filters.options.start_date = DateTime.local().startOf('week').format("yyyy-MM-dd");
                this.filters.options.end_date = DateTime.local().endOf('week').format("yyyy-MM-dd");
            }else if(this.showBlacklistWeek === true){
                this.filters.options.start_date = DateTime.local().startOf('week').format("yyyy-MM-dd");
                this.filters.options.end_date = DateTime.local().endOf('week').format("yyyy-MM-dd");
                this.filters.options.status = 'blacklisted';
            }else if(this.showMonth === true){
                this.filters.options.start_date = DateTime.local().startOf('month').format("yyyy-MM-dd");
                this.filters.options.end_date = DateTime.local().endOf('month').format("yyyy-MM-dd");
            }else if(this.showBlacklistMonth === true){
                this.filters.options.start_date = DateTime.local().startOf('month').format("yyyy-MM-dd");
                this.filters.options.end_date = DateTime.local().endOf('month').format("yyyy-MM-dd");
                this.filters.options.status = 'blacklisted';
            }else if(this.showBlacklist === true){
                this.filters.options.status = 'blacklisted';
            }

            this.search(1, true);

            axios.get(`https://api.varsanpr.com/api/clients/${this.$store.state.auth.user.selectedClient}/sites`, {
                headers: authHeader()
            })
                .then(response => {
                    this.sites = response.data;
                    $(".select2").select2();
                })
                .catch(error => {
                    this.$error("Unable to update site list", error);
                });

            this.searchCameras();

            $('.fc-datepicker').datepicker({
                dateFormat: "dd/mm/yy"
            });
            document.addEventListener('keydown', (e) => {
                if(e.code === 'Escape'){
                    this.closeImage();
                }
            });
        },
        beforeRouteLeave(to, from, next){
            document.removeEventListener('keydown', (e) => {
                if(e.code === 'Escape'){
                    this.closeImage();
                }
            });
            next();
        },
        methods: {
            searchCameras(){
                axios.get(`https://api.varsanpr.com/api/camera?count=200`, {
                    headers: authHeader(),
                    params: {
                        site_id: this.filters.options.site === "" ? null : this.filters.options.site,
                        client_id: this.$store.state.auth.user.selectedClient
                    }
                })
                    .then(response => {
                        this.cameras = response.data.cameras;
                        this.filters.options.camera = "";
                        $(".select2").select2();
                    })
                    .catch(error => {
                        this.$error("Unable to load Cameras", error);
                    });
            },
            imageLoaded(){
                this.popups.event.imageLoading = false;
                this.magnify('event_image_popup', 3);
            },
            closeImage(){
                this.popups.event.showing = false;
                this.popups.event.imageUrl = "";
                this.popups.event.imageLoading = false;
            },
            showImage(event){
                this.popups.event.event = event;
                // this.popups.event.imageUrl = 'https://api.varsanpr.com/test/admin/events/' + event.id + '/image?token='+this.user.socket_token+'&site_id=' + event.site.id;
                this.popups.event.showing = true;
                this.popups.event.imageLoading = true;
            },
            magnify(imgID, zoom) {
                //https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
                var img, glass, w, h, bw;
                img = document.getElementById(imgID);
                /*create magnifier glass:*/
                glass = document.createElement("DIV");
                glass.setAttribute("class", "img-magnifier-glass");
                /*insert magnifier glass:*/
                img.parentElement.insertBefore(glass, img);
                /*set background properties for the magnifier glass:*/
                glass.style.backgroundImage = "url('" + img.src + "')";
                glass.style.backgroundRepeat = "no-repeat";
                glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
                bw = 3;
                w = glass.offsetWidth / 2;
                h = glass.offsetHeight / 2;
                /*execute a function when someone moves the magnifier glass over the image:*/
                glass.addEventListener("mousemove", moveMagnifier);
                img.addEventListener("mousemove", moveMagnifier);
                /*and also for touch screens:*/
                glass.addEventListener("touchmove", moveMagnifier);
                img.addEventListener("touchmove", moveMagnifier);
                function moveMagnifier(e) {
                    var pos, x, y;
                    /*prevent any other actions that may occur when moving over the image*/
                    e.preventDefault();
                    /*get the cursor's x and y positions:*/
                    pos = getCursorPos(e);
                    x = pos.x;
                    y = pos.y;
                    glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
                    /*prevent the magnifier glass from being positioned outside the image:*/
                    if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
                    if (x < w / zoom) {x = w / zoom;}
                    if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
                    if (y < h / zoom) {y = h / zoom;}
                    /*set the position of the magnifier glass:*/
                    glass.style.left = (x - w) + "px";
                    glass.style.top = (y - h) + "px";
                    /*display what the magnifier glass "sees":*/
                    glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
                }
                function getCursorPos(e) {
                    var a, x = 0, y = 0;
                    e = e || window.event;
                    /*get the x and y positions of the image:*/
                    a = img.getBoundingClientRect();
                    /*calculate the cursor's x and y coordinates, relative to the image:*/
                    x = e.pageX - a.left;
                    y = e.pageY - a.top;
                    /*consider any page scrolling:*/
                    x = x - window.pageXOffset;
                    y = y - window.pageYOffset;
                    return {x : x, y : y};
                }
            },
            siteChanged(){
                this.searchCameras();
            },
            click(){
                this.$router.push({name: 'createEvent'});
            },
            loadEvent(id){
                this.$router.push(`/events/${id}`);
            },
            pagiSearch(page){
                return this.search(page, true);
            },
            search(page, filtered = false){
                let url = `https://api.varsanpr.com/api/event`;
                let query = [{name: 'client_id', value: this.$store.state.auth.user.selectedClient}, {name: 'count', value: 25}, {name: 'page', value: page}];
                if(this.qc(this.filters.options.site)){
                    query.push({name: "site", value: this.filters.options.site});
                }
                if(this.qc(this.filters.options.registration)){
                    query.push({name: "registration", value: this.filters.options.registration.replace(/\s/g, '')});
                }
                if(this.qc(this.filters.options.camera)){
                    query.push({name: "camera", value: this.filters.options.camera});
                }
                if(this.qc(this.filters.options.direction)){
                    query.push({name: "direction", value: this.filters.options.direction});
                }
                if(this.qc(this.filters.options.status)){
                    query.push({name: "status", value: this.filters.options.status});
                }
                if(this.qc(this.filters.options.start_date)){
                    query.push({name: "start_date", value: DateTime.fromFormat(this.filters.options.start_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')});
                }
                if(this.qc(this.filters.options.end_date)){
                    query.push({name: "end_date", value: DateTime.fromFormat(this.filters.options.end_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')});
                }
                if(this.qc(this.filters.options.start_time)){
                    query.push({name: "start_time", value: this.filters.options.start_time});
                }
                if(this.qc(this.filters.options.end_time)){
                    query.push({name: "end_time", value: this.filters.options.end_time});
                }

                if(query.length > 0){
                    let queryString = "?";
                    query.forEach((q) => {
                        queryString = `${queryString}${q.name}=${q.value}&`;
                    });
                    queryString = queryString.slice(0, -1);
                    url = `${url}${queryString}`;
                }
                this.loading = true;
                axios.get(url, {
                    headers: authHeader()
                })
                    .then(response => {
                        response.data.events.forEach((event) => {
                            event.created_at = DateTime.fromSeconds(parseInt(event.created_at)).toFormat('yyyy-MM-dd HH:mm:ss');
                            event.io = "";
                            if(event.direction === 'forward'){
                                event.io = 'In';
                            }
                            if(event.direction === 'reverse'){
                                event.io = 'Out';
                            }
                            if(event.status === 'blacklisted'){
                                event.blacklisted = true;
                            }
                        });
                        this.events = response.data.events;
                        this.pagination.currPage = response.data.pagination.page;
                        this.pagination.totalResults = response.data.pagination.total;
                        this.pagination.from = 1 + (response.data.pagination.page -1) * response.data.pagination.showing;
                        this.pagination.to = 1 + response.data.pagination.page * response.data.pagination.showing;
                        if(this.pagination.to > this.pagination.totalResults){
                            this.pagination.to = this.pagination.totalResults;
                        }
                        this.pagination.perPage = response.data.pagination.showing;
                        this.pagination.lastPage = response.data.pagination.lastPage;

                        this.loading = false;
                    })
                    .catch(error => {
                        this.$error("Failed to load events", error);
                        this.loading = false;
                    });
            },
            qc(val){
                if(val === null) return false;
                if(val === undefined) return false;

                if(typeof val === 'string'){
                    if(val.length < 1) return false;
                }

                return true;
            },
            async watchlist(e){
                const {value: reason} = await Swal.fire({
                    title: `Select a reason for blacklisting ${e.vehicle.plate}`,
                    input: 'select',
                    inputOptions: {
                        "Drive Off": "Drive Off",
                        "No Means of Payment": "No Means of Payment",
                        "Fly Tipping": "Fly Tipping",
                        "Theft": "Theft",
                        "Aggressive": "Aggressive",
                        "Plate Mismatch": "Plate Mismatch"
                    },
                    inputPlaceholder: 'Select a reason',
                    showCancelButton: true
                });

                if(reason){
                    axios.put(`https://api.varsanpr.com/api/event/blacklist/${e.id}`, {
                        site_id: e.site.id,
                        reason: reason
                    }, {
                        headers: authHeader()
                    })
                        .then(response => {
                            e.status = 'blacklisted';
                            this.events.forEach((event) => {
                                if(e.vehicle.plate === event.vehicle.plate){
                                    event.status = 'blacklisted';
                                }
                            });
                            this.$success(`Added ${e.vehicle.plate} to blacklist`);
                        })
                        .catch(error => {
                            console.error("Failed adding to blacklist");
                            this.$error("Failed adding to blacklist", error);
                        });
                }
            },
            unwatchlist(e){
                axios.delete(`https://api.varsanpr.com/api/event/blacklist/${e.id}`, {
                    data: {
                        site_id: e.site.id
                    },
                    headers: authHeader()
                })
                    .then(response => {
                        e.status = 'active';
                        e.blacklisted = false;
                        this.events.forEach((event) => {
                            if(e.vehicle.plate === event.vehicle.plate){
                                event.status = 'blacklisted';
                            }
                        });
                        this.$success(`Removed ${e.vehicle.plate} from blacklist`);
                    })
                    .catch(error => {
                        console.error("Failed removing from blacklist");
                        this.$error("Failed removing from blacklist", error);
                    });
            },
            toggleFilters(){
                this.filters.open = !this.filters.open;
            },
            applyFilters(){
                this.filters.selectedOptions = JSON.parse(JSON.stringify(this.filters.options));
                this.search(1, true);
            },
            clearFilters(){
                this.filters.options = {
                    registration: "",
                    site: "",
                    camera: "",
                    direction: "",
                    status: "",
                    start_date: "",
                    end_date: "",
                    start_time: "",
                    end_time: ""
                };
                this.filters.selectedOptions = JSON.parse(JSON.stringify(this.filters.options));
                this.search(1);
                $(".select2").select2();
            }
        },
        computed: {
            selectedFilters: function(){
                let count = 0;
                if(this.filters.selectedOptions.registration.toString().length > 0) count++;
                if(this.filters.selectedOptions.site.toString().length > 0) count++;
                if(this.filters.selectedOptions.camera.toString().length > 0) count++;
                if(this.filters.selectedOptions.direction.toString().length > 0) count++;
                if(this.filters.selectedOptions.status.toString().length > 0) count++;
                if(this.filters.selectedOptions.start_date.toString().length > 0) count++;
                if(this.filters.selectedOptions.end_date.toString().length > 0) count++;
                if(this.filters.selectedOptions.start_time.toString().length > 0) count++;
                if(this.filters.selectedOptions.end_time.toString().length > 0) count++;
                return count;
            },
            optionsMatch: function (){
                return JSON.stringify(this.filters.options) === JSON.stringify(this.filters.selectedOptions);
            }
        }
    }
</script>

<style scoped>

</style>
